import React, { ReactNode, useEffect, useState } from 'react';
import { Autocomplete, Box, Checkbox, Chip } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useReinitializeForm from '../../hooks/useReinitializeForm';
import ContractsCustomTextField from '../Contracts/ContractsCustomTextField';
import useDisableItemButton from '../../hooks/useDisableItemButton';

type SelectOptionsType = {
  optionLabel: ReactNode;
  label: string;
  value: unknown;
  group: string;
}[];

const MultiValuesSelectDropdown = ({
  options,
  onChange,
  defaultValue = [],
  required
}: {
  options: SelectOptionsType;
  onChange: (data: SelectOptionsType) => void;
  defaultValue: SelectOptionsType;
  required?: boolean;
}) => {
  const [value, setValue] = useState<SelectOptionsType>(defaultValue);
  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  const reset = useReinitializeForm();
  const { hasError, setHasError } = useDisableItemButton({
    required: required === true,
    hasValue: value.length > 0
  });

  useEffect(() => {
    const isError = !value && hasBeenClicked && required ? true : false;
    setHasError && setHasError(isError);
  }, [value, hasBeenClicked, required, setHasError]);

  useEffect(() => {
    reset && setValue([]);
  }, [reset]);

  return (
    <Box onClick={() => setHasBeenClicked(true)}>
      <Autocomplete
        className="w-100"
        multiple
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue);
          onChange(newValue);
          if (newValue) {
            setHasError(false);
          }
        }}
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        groupBy={(option) => option.group}
        renderOption={(props, option) => {
          const checked = value.find((el) => el.value === option.value) !== undefined;
          return (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={checked}
              />
              {option.optionLabel}
            </li>
          );
        }}
        renderTags={(values: SelectOptionsType, getTagProps: any) => {
          return values?.map((value: any, index: number) => {
            return (
              <Chip
                key={'chip' + index}
                // variant="outlined"
                label={value?.label}
                sx={{
                  height: '22px'
                }}
                {...getTagProps({ index })}
              />
            );
          });
        }}
        isOptionEqualToValue={(option: any, value: any) => {
          return option.value === value.value;
        }}
        renderInput={(params) => (
          <ContractsCustomTextField {...params} size="small" error={hasError === true} />
        )}
      />
      {hasError && (
        <Box
          sx={{
            fontWeight: 400,
            fontSize: '0.75rem',
            margin: '3px 14px 0',
            color: '#d32f2f',
            lineHeight: 1.66,
            letterSpacing: '0.03333em'
          }}
        >
          Merci de renseigner le contrat / client
        </Box>
      )}
    </Box>
  );
};

export default MultiValuesSelectDropdown;
