import React, { useEffect, useState } from 'react';
import {
  AppPaper,
  BirdzTitle,
  ListPage,
  SearchForm,
  SearchField,
  ListField
} from '@applications-terrains/birdz-react-library';
import Visibility from '@mui/icons-material/Visibility';
import Edit from '@mui/icons-material/Edit';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  useGetAgents,
  useGetBConnectContracts,
  useGetCities,
  useGetModuleFamilies
} from '../../../hooks/datarefs';
import ExportPopup from './ExportPopup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import useReinitializeForm from '../../../hooks/useReinitializeForm';
import axios from 'axios';
import { CompanyProps } from './types';
import BConnectClientsSearchField from './BConnectClientsSearchField';

export default function Interventions({ target = 'all' }: { target: 'all' | 'pending' }) {
  const endpoints = {
    all: '/api/boi/extractor/interventions/',
    pending: '/api/exocet/unfinished-interventions'
  };
  const endpoint = endpoints[target];
  const { data: cities } = useGetCities();
  const { data: contracts } = useGetBConnectContracts();
  const { data: agents } = useGetAgents();
  const { data: moduleFamilies } = useGetModuleFamilies();
  const reset = useReinitializeForm();

  const [filters, setFilters] = useState<string>('');
  const [contractString, setContractString] = useState<string>('');
  const [companies, setCompanies] = useState<CompanyProps[]>([]);

  useEffect(() => {
    reset && setContractString('');
  }, [reset]);

  const fetchCompanies = async () => {
    const { data } = await axios.get('/api/boi/extractor/clients');
    const companies = data.results;
    setCompanies(companies);
  };

  useEffect(() => {
    fetchCompanies();
  }, [target]);

  const searchFields = [
    // target === 'pending' && {
    //   name: 'bconnect_id',
    //   label: 'Client',
    //   size: 12,
    //   labelSize: 1.49,
    //   fieldSize: 10.51,
    //   type: 'select',
    //   options: {
    //     identifier: 'value',
    //     label: 'label',
    //     values: companies?.map((company) => {
    //       return { value: company.bconnect_id, label: company.name };
    //     })
    //   }
    // },
    target === 'all' && {
      name: 'client',
      label: 'Client',
      type: 'select',
      options: {
        identifier: 'value',
        label: 'label',
        values: companies?.map((company) => {
          return { value: company.id, label: company.name };
        })
      }
    },
    target === 'all' && {
      name: 'contract_id',
      label: 'Contrat',
      type: 'select',
      options: {
        values: contracts
          ? contracts?.map((contract) => {
              return {
                value: contract.contract_id,
                label: contract.contract_name
              };
            })
          : []
      }
    },
    {
      name: 'reference',
      label: 'Reference',
      options: {
        identifier: 'reference',
        source: endpoint,
        label: 'reference',
        searchIsMulti: false
      }
    },
    {
      name: 'intervention_type',
      label: "Type d'intervention",
      type: 'select',
      options: {
        values: [
          { value: 'EligibilityAudit', label: 'Audit' },
          { value: 'AttributeInstall', label: "Installation d'un attribut" },
          { value: 'AttributeMaintenance', label: "Maintenance d'un attribut" },
          { value: 'MeterInstall', label: "Installation d'un compteur" },
          { value: 'MeterMaintenance', label: "Maintenance d'un compteur" },
          { value: 'DeviceInstall', label: "Installation d'un module" },
          { value: 'DeviceDump', label: "Dépose d'un module" },
          { value: 'DeviceReinstall', label: "Repose d'un module" },
          { value: 'DeviceMaintenance', label: "Maintenance d'un module" },
          { value: 'RepeaterMaintenance', label: "Maintenance d'un répéteur / Bridge" },
          { value: 'VisualIndex', label: 'Relève visuelle' },
          { value: 'RadioIndex', label: 'Radio-relevé' },
          { value: 'DeviceUpdate', label: 'MAJ des informations du module' },
          { value: 'RepeaterUpdate', label: 'MAJ des informations du répéteur' },
          {
            value: 'MeterMaintenanceWithCs',
            label: "Maintenance d'un compteur avec enquête branchement"
          },
          {
            value: 'DeviceMaintenanceWithCs',
            label: "Maintenance d'un module avec enquête branchement"
          },
          {
            value: 'MeterInstallWithCs',
            label: "Installation d'un compteur avec enquête branchement"
          },
          {
            value: 'DeviceInstallWithCs',
            label: "Installation d'un module avec enquête branchement"
          },
          { value: 'ConnectionSurveyWithCs', label: 'Enquête branchement' },
          { value: 'DeviceInstallLight', label: "Installation d'un module - Light" },
          { value: 'DeviceMaintenanceLight', label: "Maintenance d'un module - Light" },
          { value: 'RadioIndexRead', label: 'Radio-relevé unitaire' }
        ]
      }
    },
    target === 'all' && {
      name: 'validation_status',
      label: 'Statut validation',
      type: 'select',
      options: {
        values: [
          { value: 'TO_VALIDATE', label: 'A valider' },
          { value: 'VALIDATED', label: 'Validée' }
        ]
      }
    },
    target === 'all' && {
      name: 'anomaly_status',
      label: 'Statut anomalie',
      type: 'select',
      options: {
        values: [
          { value: 'WITHOUT_ANOMALY', label: 'Sans anomalie' },
          { value: 'IN_ANOMALY', label: 'En anomalie' },
          { value: 'CORRECTED', label: 'Corrigée' }
        ]
      }
    },
    target === 'all' && {
      name: 'anomaly_type',
      label: "Type d'anomalie",
      type: 'select',
      options: {
        values: [
          { value: 'Appairage compteur / module', label: 'Appairage compteur / module' },
          { value: 'Doublons compteurs', label: 'Doublons compteurs' },
          { value: 'Doublons modules', label: 'Doublons modules' },
          { value: 'Index de pose compteur douteux', label: 'Index de pose compteur douteux' },
          { value: 'Index de dépose douteux', label: 'Index de dépose douteux' },
          { value: 'Compteur déposé inconnu', label: 'Compteur déposé inconnu' },
          {
            value: 'Adresse radio du module posé incorrecte',
            label: 'Adresse radio du module posé incorrecte'
          },
          {
            value: "Adresse radio de l'équipement de répétition incorrecte",
            label: "Adresse radio de l'équipement de répétition incorrecte"
          },
          { value: 'Coordonnées GPS absentes', label: 'Coordonnées GPS absentes' },
          {
            value: 'Coordonnées GPS en dehors du périmètre',
            label: 'Coordonnées GPS en dehors du périmètre'
          }
        ]
      },
      seeMore: true
    },
    target === 'all' && {
      name: 'agent',
      label: 'Agent',
      type: 'select',
      options: {
        identifier: 'value',
        label: 'label',
        values: agents?.map((agent) => {
          return { value: agent.Name, label: agent.Name };
        })
      },
      seeMore: true
    },
    target === 'all' && { name: 'counter', label: 'Capteur', type: 'text', seeMore: true },
    target === 'all' && { name: 'device_id', label: 'Module', type: 'text', seeMore: true },
    target === 'all' && {
      name: 'module_family',
      label: 'Famille de module',
      type: 'select',
      options: {
        values: moduleFamilies?.map((family) => {
          return { value: family.Name, label: family.Name };
        })
      },
      seeMore: true
    },
    target === 'all' && {
      name: 'city',
      label: 'Commune',
      type: 'select',
      options: {
        values:
          cities && cities.length
            ? cities.map((city) => {
                return {
                  value: city,
                  label: city
                };
              })
            : []
      },
      seeMore: true
    },
    target === 'all' && {
      name: 'equipment_parent',
      label: 'Equipement parent',
      type: 'text',
      seeMore: true
    },
    target === 'all' && { name: 'pds', label: 'PDS Client', type: 'text', seeMore: true },
    target === 'all' && {
      name: 'created_at_after',
      label: 'Création entre le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && {
      name: 'created_at_before',
      label: 'et le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && {
      name: 'closing_at_after',
      label: 'Réalisation entre le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && {
      name: 'closing_at_before',
      label: 'et le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && {
      name: 'updated_at_after',
      label: 'Mis à jour entre le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && {
      name: 'updated_at_before',
      label: 'et le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && {
      name: 'exported_at_after',
      label: 'Exportée entre le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && {
      name: 'exported_at_before',
      label: 'et le',
      type: 'datepicker',
      seeMore: true
    }
  ].filter((el) => el) as SearchField[];

  const listFields = [
    target === 'all' && {
      name: 'anomaly_status',
      label: 'Statut anomalie',
      transform: (value: string) => {
        if (value === 'IN_ANOMALY') {
          return (
            <Tooltip title="En anomalie">
              <span>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ReportProblemIcon style={{ color: '#e60000' }} />
                </Box>
              </span>
            </Tooltip>
          );
        } else if (value === 'WITHOUT_ANOMALY') {
          return <Box sx={{ display: 'flex', justifyContent: 'center' }}>Aucune</Box>;
        } else if (value === 'CORRECTED') {
          return <Box sx={{ display: 'flex', justifyContent: 'center' }}>Corrigée</Box>;
        } else return value || '-';
      }
    },
    target === 'all' && {
      name: 'validation_status',
      label: 'Statut validation',
      transform: (value: string) => {
        switch (value) {
          case 'VALIDATED':
            return (
              <Tooltip title="Validée">
                <span>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CheckCircleIcon style={{ color: 'green' }} />
                  </Box>
                </span>
              </Tooltip>
            );
          case 'TO_VALIDATE':
            return (
              <Tooltip title="A valider">
                <span>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <EditCalendarIcon style={{ color: '#0000e6' }} />
                  </Box>
                </span>
              </Tooltip>
            );

          default:
            return value || '-';
        }
      }
    },
    { name: `${target === 'all' ? 'data.' : ''}CLIENT`, label: 'Client' },
    { name: 'contract_name', label: 'Contrat' },
    { name: 'reference', label: 'Réf' },
    { name: 'module_family', label: 'Famille de module' },
    { name: 'city', label: 'Commune' },
    { name: 'agent', label: 'Agent' },
    {
      name: 'intervention_type',
      label: 'Type intervention',
      transform(value: string) {
        switch (value) {
          case 'EligibilityAudit':
            return 'Audit';
          case 'AttributeInstall':
            return "Installation d'un attribut";
          case 'AttributeMaintenance':
            return "Maintenance d'un attribut";
          case 'MeterInstall':
            return "Installation d'un compteur";
          case 'MeterMaintenance':
            return "Maintenance d'un compteur";
          case 'DeviceInstall':
            return "Installation d'un module";
          case 'DeviceDump':
            return "Dépose d'un module";
          case 'DeviceReinstall':
            return "Repose d'un module";
          case 'DeviceMaintenance':
            return "Maintenance d'un module";
          case 'RepeaterMaintenance':
            return "Maintenance d'un répéteur / Bridge";
          case 'VisualIndex':
            return 'Relève visuelle';
          case 'RadioIndex':
            return 'Radio-relevé';
          case 'DeviceUpdate':
            return 'MAJ des informations du module';
          case 'RepeaterUpdate':
            return 'MAJ des informations du répéteur';
          case 'MeterMaintenanceWithCs':
            return "Maintenance d'un compteur avec enquête branchement";
          case 'DeviceMaintenanceWithCs':
            return "Maintenance d'un module avec enquête branchement";
          case 'MeterInstallWithCs':
            return "Installation d'un compteur avec enquête branchement";
          case 'DeviceInstallWithCs':
            return "Installation d'un module avec enquête branchement";
          case 'ConnectionSurveyWithCs':
            return 'Enquête branchement';
          case 'DeviceInstallLight':
            return "Installation d'un module - Light";
          case 'DeviceMaintenanceLight':
            return "Maintenance d'un module - Light";
          case 'RadioIndexRead':
            return 'Radio-relevé unitaire';
          default:
            return value;
        }
      }
    },
    {
      name: 'bconnect_intervention_status',
      label: 'Statut intervention',
      transform(value: string) {
        if (value === 'Done') {
          return 'Terminée';
        } else {
          return value;
        }
      }
    },
    target === 'all' && {
      name: 'data.Motif annulation',
      label: 'Motif statut'
    },
    {
      name: 'intervention_creation_date',
      label: 'Date de création',
      transform(value: any) {
        return value ? (
          moment(value).format('DD/MM/YYYY') + ' à ' + moment(value).format('HH:mm')
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>-</Box>
        );
      }
    },
    target === 'all' && {
      name: 'updated_at',
      label: 'Date de mise à jour',
      transform(value: number) {
        return value ? (
          moment.unix(value).format('DD/MM/YYYY') + ' à ' + moment.unix(value).format('HH:mm')
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>-</Box>
        );
      }
    },
    target === 'all' && {
      name: 'closing_date',
      label: 'Date de réalisation',
      transform(value: any) {
        return value ? (
          moment(value).format('DD/MM/YYYY') + ' à ' + moment(value).format('HH:mm')
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>-</Box>
        );
      }
    },
    target === 'all' && {
      name: 'nb_exports',
      label: 'Nb exports',
      transform(value: number) {
        return <Box sx={{ display: 'flex', justifyContent: 'center' }}>{value}</Box>;
      }
    },
    target === 'all' && { name: 'last_export_date', label: 'Date dernier export' },
    { name: 'pds', label: 'PDS' },
    {
      name: 'counter',
      label: 'Capteur',
      transform(value: any) {
        return value ? value : <Box sx={{ display: 'flex', justifyContent: 'center' }}>-</Box>;
      }
    },
    {
      name: 'device_id',
      label: 'Module',
      transform(value: any) {
        return value ? value : <Box sx={{ display: 'flex', justifyContent: 'center' }}>-</Box>;
      }
    },
    {
      name: 'data[Repeteur Parent]',
      label: 'Equipement parent',
      transform(value: any) {
        return value ? value : <Box sx={{ display: 'flex', justifyContent: 'center' }}>-</Box>;
      }
    }
  ].filter((el) => el) as ListField[];

  const actions = [
    {
      name: 'view',
      label: 'Consulter',
      render: (row: any) => {
        return (
          <IconButton
            component={Link}
            to={
              target === 'all'
                ? `/boi/extractor/interventions/view/${row.reference}`
                : `/bconnect/interventions/pending/${row.id}`
            }
          >
            <Visibility fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'edit',
      label: 'Modifier',
      render: (row: any) => {
        if (row.bconnect_intervention_status === 'Done' && row.client) {
          return (
            <IconButton component={Link} to={`/boi/extractor/interventions/edit/${row.reference}`}>
              <Edit fontSize="small" />
            </IconButton>
          );
        }
        return <></>;
      }
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <BirdzTitle>Suivi des interventions</BirdzTitle>
      </Grid>

      <AppPaper sx={{ pt: 2 }}>
        {target === 'pending' && (
          <Grid container alignItems={'center'} sx={{ my: 2, mt: 0, p: 0 }}>
            <Grid item xs={1.49}>
              {'Client'}
            </Grid>
            <Grid item xs={10.51}>
              <BConnectClientsSearchField
                onChange={(contractNb) => setContractString('&client=' + contractNb)}
                endpoint="/api/bconnect/clients/"
                searchKey="name"
                refresh={reset}
              />
            </Grid>
          </Grid>
        )}
        <SearchForm
          onSubmit={(filterString: string) => {
            setFilters(contractString + filterString);
          }}
          fields={searchFields}
        />
      </AppPaper>
      <ExportPopup filter={filters} />

      <ListPage endpoint={endpoint} fields={listFields} filters={filters} actions={actions} />
    </AppPaper>
  );
}
